
import { isEmpty } from "lodash";
export default {
  name: "MenuAdministracion",
  data() {
    return {
      rutasMenu: [
        {
          icono: "inicio",
          label: "Home",
          to: "home", // Se define si el modulo no tiene submodulos y se omite la prop menuLateral
          menuLateral: [],
        },
        {
          icono: "prospecto",
          label: "Contactos",
          to: "leadsGestor",
          menuLateral: [],
          rutasRelacionadas: ["leadsDetalle"],
        },
        {
          icono: "articulo",
          label: "Articulos",
          to: "productosGestor",
          menuLateral: [],
          rutasRelacionadas: ["productosDetalle"],
        },
        {
          icono: "oportunidad",
          label: "Oportunidades",
          to: "oportunidadesGestor",
          menuLateral: [],
          rutasRelacionadas: ["oportunidadesDetalle"],
        },
        {
          icono: "conversacion",
          label: "Conversaciones",
          to: "conversacionesGestor",
          menuLateral: [],
        },
        {
          icono: "enviar",
          label: "Emails enviados",
          to: "emailsGestor",
          menuLateral: [],
        },
        {
          icono: "filtrar",
          label: "Embudo de ventas",
          to: "embudoVentasGestor",
          menuLateral: [],
        },
        {
          icono: "seguimiento",
          label: "Seguimientos",
          to: "seguimientosGestor",
          menuLateral: [],
          rutasRelacionadas: ["seguimientosDetalle"],
        },
      ],
    };
  },
  methods: {
    isEmpty,
    validarActiveRuta(ruta) {
      let rutaValida = false;

      if (isEmpty(ruta.menuLateral)) {
        if (
          this.$route.name == ruta.to ||
          _.filter(ruta.rutasRelacionadas, (rr) => this.$route.name === rr)
            .length > 0
        )
          rutaValida = true;
      } else {
        ruta.menuLateral.forEach((ml) => {
          ml.opciones.forEach((r) => {
            if (
              this.$route.name === r.ruta ||
              _.filter(r.rutasRelacionadas, (rr) => this.$route.name === rr)
                .length > 0
            )
              rutaValida = true;
          });
        });
      }

      return rutaValida;
    },
    validarRutasHijos(opcion) {
      return (
        opcion.ruta !== "" &&
        (this.$route.name === opcion.ruta ||
          _.filter(opcion.rutasRelacionadas, (rr) => this.$route.name === rr)
            .length > 0)
      );
    },
  },
};
