
export default {
  name: "Layout-Header",
  props: { menus: Array, menusExtra: Array, menuActivo: Number },
  methods: {
    cargarMenu(menu) {
      this.$emit("cargar-menu", menu);
    },
    calcularEspaciadoBoton(indice) {
      if (indice == this.menus.length && this.menusExtra.length == 0)
        return false;
      else return true;
    },
  },
};
