
import ConversacionService from "../../Services/ConversacionService";
import MenuAdministracion from "../menus/MenuAdministracion.vue";
import LayoutHeader from "./LayoutHeader.vue";
// import { ConversacionEventBus } from "../../EventBuses/ConversacionEventBus";
import { Client } from "@stomp/stompjs";
import MenuLayoutGlobalConfiguraciones from "./componentes/MenuLayoutGlobalConfiguraciones.vue";

export default {
  name: "template-view",
  components: {
    MenuAdministracion,
    LayoutHeader,
    MenuLayoutGlobalConfiguraciones,
  },
  data() {
    return {
      rutaLogo: "",
      menuActivo: 1,
      menus: [
        {
          nombre: "Administración",
          indice: 1,
          componente: MenuAdministracion,
        },
      ],
      menusLayout: [],
      menusExtra: [],
      contactos: JSON.parse(localStorage.getItem("contactos")),
      conversaciones: JSON.parse(localStorage.getItem("conversaciones")),
      ultimoMensajeGlobalIndex: localStorage.getItem(
        "ultimoMensajeGlobalIndex"
      ),
      ultimaSincronizacionFecha: localStorage.getItem(
        "ultimaSincronizacionFecha"
      ),
      privilegioMensajes: localStorage.getItem("privilegioMensajes"),
      MQClienteStomp: null,
      MQUrl: "",
      MQUsuario: "",
      MQContrasenia: "",
      MQVhost: "",
      MQExchange: "crm-cliente-" + localStorage.getItem("pin"),
      intervaloId: null,
    };
  },
  mounted() {
    this.cargaInicial();
  },
  created() {
    this.$conversacionEventBus.$on(
      "cargar-conversaciones",
      this.cargarConversaciones
    );
    this.$conversacionEventBus.$on(
      "actualizar-conversacion-ultimo-mensaje-visto",
      this.actualizarConversacionUltimoMensajeVisto
    );
    this.$conversacionEventBus.$on(
      "cargar-conversaciones-anteriores",
      this.obtenerConversacionesAnteriores
    );
    this.$conversacionEventBus.$on(
      "cargar-mensajes-anteriores",
      this.obtenerMensajesAnteriores
    );
  },
  beforeDestroy() {
    clearInterval(this.intervaloId);
    this.cerrarMQ();
  },
  methods: {
    async cargaInicial() {
      this.cargarMenus();
      await this.obtenerCredencialesMQ();
      await this.conectarMQ();
      await this.cargarConversaciones();
    },
    cargarMenus() {
      let tipoMenu = 1;
      let cantidadMenus = this.menus.length;

      // Se definen menus y menus extras
      if (cantidadMenus > 4) {
        this.menusLayout = this.menus.slice(0, 4);
        this.menusExtra = this.menus.slice(4, cantidadMenus);
      } else {
        this.menusLayout = this.menus;
      }

      // Se define menu a mostrar
      if (cantidadMenus > 1) {
        tipoMenu = Number(localStorage.getItem("menu") || 1);
      }
      this.cargarMenu(tipoMenu);
    },
    cargarMenu(tipoMenu) {
      this.menuActivo = tipoMenu;
      localStorage.setItem("menu", tipoMenu.toString());
    },
    async obtenerCredencialesMQ() {
      await new Promise((resolve) => {
        this.MQUrl = process.env.VUE_APP_MQ_URL;
        this.MQUsuario = process.env.VUE_APP_MQ_USUARIO;
        this.MQContrasenia = process.env.VUE_APP_MQ_CONTRASENIA;
        this.MQVhost = process.env.VUE_APP_MQ_VHOST;
        resolve();
      });
    },
    async conectarMQ() {
      // Se crea cliente Stomp
      this.MQClienteStomp = new Client({
        brokerURL: this.MQUrl,
        connectHeaders: {
          login: this.MQUsuario,
          passcode: this.MQContrasenia,
          host: this.MQVhost,
        },
        debug: (message) => {
          console.log(message);
        },
      });

      // Se define callback al conectar
      this.MQClienteStomp.onConnect = () => {
        console.log("Conectado a RabbitMQ");

        // Se generar suscripcion a exchenge
        this.MQClienteStomp.subscribe(
          `/exchange/${this.MQExchange}`,
          (message) => {
            console.log("Mensaje recibido:", message.body);
            // Se manejan datos recibidos
            this.manejarMensajeRecibidoMQ(JSON.parse(message.body));
          }
        );
      };

      // Se conecta cliente a servidor Rabbit MQ
      this.MQClienteStomp.activate();
    },
    async cargarConversaciones() {
      this.reiniciarIntervaloFechaUltimaSincronizacion();
      // Se valida cache
      if (_.isEmpty(this.conversaciones) || _.isEmpty(this.contactos)) {
        await this.obtenerConversaciones(false);
        return;
      }

      // Se obtiene diferencia entre ultimo mensaje y fecha actual
      let fechaActual = this.dayjs(this.fechaActual());
      let fechaUltimoMensaje = this.dayjs(this.ultimaSincronizacionFecha);
      let diferenciaMinutos = fechaActual.diff(fechaUltimoMensaje, "minutes");

      // Se valida tiempo desde ultimo mensaje en minutos
      if (diferenciaMinutos > 4) await this.obtenerUltimosMensajes(false);
    },
    async obtenerConversaciones(loader = true) {
      if (loader) this.loader(true);
      await ConversacionService.listarGestor(this.filtros)
        .then((resp) => {
          this.contactos = resp.contactos;
          this.conversaciones = resp.conversaciones;
          this.ultimoMensajeGlobalIndex = resp.ultimoMensajeGlobalIndex;
          this.privilegioMensajes = resp.privilegioMensajes;

          // Se marcan contactos con conversacion inicializada
          this.marcarConversacionesInicializadas();

          // Se guarda cache de conversaciones
          this.actualizarCache("todo");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async obtenerConversacionesAnteriores(loader = true) {
      if (loader) this.loader(true);
      let filtros = {
        privilegioMensajes: this.privilegioMensajes,
      };
      await ConversacionService.obtenerConversacionesAnteriores(filtros)
        .then((resp) => {
          if (resp.conversaciones.length == this.conversaciones.length) {
            this.mostrarAlerta(
              "Ya se han cargado todas las conversaciones",
              "exito"
            );
            return;
          }

          // Se agrean conversaciones anteriores
          this.conversaciones = resp.conversaciones;

          // Se marcan contactos con conversacion inicializada
          this.marcarConversacionesInicializadas();

          // Se guarda cache de conversaciones
          this.actualizarCache("mensajes", false);

          this.$conversacionEventBus.$emit(
            "agregar-conversaciones-anteriores",
            this.conversaciones
          );
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async obtenerUltimosMensajes() {
      // if (loader) this.loader(true);
      let filtros = {
        ultimoMensajeId: this.ultimoMensajeGlobalIndex,
        privilegioMensajes: this.privilegioMensajes,
      };
      await ConversacionService.obtenerUltimosMensajes(filtros)
        .then((resp) => {
          // Se actualizan mensajes por conversacion
          this.actualizarMensajes(resp.conversaciones);

          // Se actualizan datos ultimo mensaje
          if (resp.ultimoMensajeGlobalIndex != "") {
            this.ultimoMensajeGlobalIndex = resp.ultimoMensajeGlobalIndex;
          }

          // Se marcan contactos con conversacion inicializada
          this.marcarConversacionesInicializadas();

          // Se actualiza cache
          this.actualizarCache();
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          // if (loader) this.loader(false);
        });
    },
    async obtenerConversacion(leadId) {
      // if (loader) this.loader(true);
      await ConversacionService.obtenerConversacion({ contactoId: leadId })
        .then((resp) => {
          // // Se actualizan mensajes por conversacion
          this.actualizarMensajes([resp.conversacion]);
          // Se actualizan datos ultimo mensaje
          if (resp.ultimoMensajeGlobalIndex != "") {
            this.ultimoMensajeGlobalIndex = resp.ultimoMensajeGlobalIndex;
          }

          // Se marcan contactos con conversacion inicializada
          this.marcarConversacionesInicializadas();
          // Se actualiza cache
          this.actualizarCache();
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          // if (loader) this.loader(false);
        });
    },
    async obtenerMensajesAnteriores(leadId, primerMensajeId, plataforma) {
      this.loader(true);
      let filtros = {
        leadId,
        primerMensajeId,
        plataforma,
      };
      await ConversacionService.obtenerMensajesAnteriores(filtros)
        .then((resp) => {
          let mensajes = resp.mensajes;
          if (_.isEmpty(mensajes)) {
            this.mostrarAlerta("Ya se han cargado todos los mensajes", "exito");
            return;
          }
          let index = this.conversaciones.findIndex(
            (c) => c.lead_id == leadId && c.plataforma == plataforma
          );
          this.conversaciones[index].mensajes =
            this.conversaciones[index].mensajes.concat(mensajes);
          // Se guarda cache de conversaciones
          this.actualizarCache("mensajes", false);
          this.$conversacionEventBus.$emit(
            "agregar-mensajes-anteriores",
            this.conversaciones,
            mensajes
          );
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          this.loader(false);
        });
    },
    actualizarMensajes(conversacionesNuevas) {
      conversacionesNuevas.reverse();
      conversacionesNuevas.forEach((convNueva) => {
        let conversacionIndex = this.conversaciones.findIndex(
          (conv) => conv.lead_id == convNueva.lead_id
        );

        // Se actualizan datos de la conversacion
        if (conversacionIndex >= 0) {
          let conversacionActual = this.conversaciones[conversacionIndex];
          // Se actualizan mensajes
          conversacionActual.mensajes = convNueva.mensajes.concat(
            conversacionActual.mensajes
          );
          // Se actualiza index ultimo mensaje
          conversacionActual.ultimo_mensaje_index =
            convNueva.ultimo_mensaje_index;
          // Se actualiza fecha ultimo mensaje
          conversacionActual.ultimo_mensaje_fecha =
            convNueva.ultimo_mensaje_fecha;
          // Se actualiza orden de conversacion
          this.conversaciones.splice(conversacionIndex, 1);
          this.conversaciones.unshift(conversacionActual);
        } else {
          this.conversaciones.unshift(convNueva);
        }
      });
    },
    marcarConversacionesInicializadas() {
      this.contactos.forEach((contacto) => {
        let conversaciones = this.conversaciones.filter(
          (conversacion) => contacto.lead_id == conversacion.lead_id
        );

        if (conversaciones.length > 0) {
          // Se marca conversacion como incializada
          contacto.inicializado = true;
          // Se actualiza numero por si no habia sido cargado
          contacto.telefono = conversaciones[0].telefono;
        } else contacto.inicializado = false;
      });
    },
    async manejarMensajeRecibidoMQ(mensaje) {
      if (!_.isEmpty(mensaje)) {
        if (mensaje.tipo == "actualizacionStatus") {
          this.actualizarStatusMensajeEnviado(
            mensaje.message_sid,
            mensaje.status_nuevo
          );
          return;
        }
        // Se obtiene index de contacto desde cache
        let contactoIndex = this.contactos.findIndex(
          (cont) => cont.lead_id == mensaje.leadId
        );

        // Se valida privilegio de mensajes
        if (this.privilegioMensajes != "todos" && contactoIndex < 0) {
          console.log("Mensajes descartado por falta de privilegios");
          return;
        }

        // Se agrega contacto si no existe en cache (solo aplica con privilegio todos validado por if anterior )
        if (contactoIndex < 0) {
          console.log("Se incializa contacto nuevo");
          let agregarContactoObj = {
            lead_id: mensaje.leadId,
            nombre_completo: mensaje.leadNombre,
            telefono: mensaje.numeroContacto,
          };
          this.contactos.unshift(agregarContactoObj);
          console.log("Caso agregar contacto cache, obtener conversacion");
          await this.obtenerConversacion(mensaje.leadId);
          this.$conversacionEventBus.$emit(
            "actualizar-conversaciones-mensaje-recibido",
            this.conversaciones
          );
          this.reproducirNotificacion();
          return;
        } else {
          let contactoInicializado = this.contactos[contactoIndex].inicializado;
          if (contactoInicializado) {
            // Se agrega mensaje a conversacion
            console.log("Caso agregar mensaje cache");
            this.agregarMensajeConversacion(mensaje, contactoIndex);
            return;
          } else {
            console.log("caso obtener conversacion");
            await this.obtenerConversacion(mensaje.leadId);
            this.$conversacionEventBus.$emit(
              "actualizar-conversaciones-mensaje-recibido",
              this.conversaciones
            );
            this.reproducirNotificacion();
          }
        }
      } else {
        console.log("Recepcion de mensajes sin informacion");
      }
    },
    agregarMensajeConversacion(mensaje) {
      let conversacionIndex = this.conversaciones.findIndex(
        (conv) => conv.lead_id == mensaje.leadId
      );
      if (conversacionIndex >= 0) {
        let mensajeContenido = JSON.parse(mensaje.mensaje);
        let conversacionActual = this.conversaciones[conversacionIndex];
        // Se actualizan mensajes
        conversacionActual.mensajes.unshift(mensajeContenido);
        // Se actualiza index ultimo mensaje
        conversacionActual.ultimo_mensaje_index =
          mensajeContenido.mensaje_plataforma_id;
        // Se actualiza fecha ultimo mensaje
        conversacionActual.ultimo_mensaje_fecha = mensaje.mensajeFecha;
        // Se actualiza orden de conversacion
        this.conversaciones.splice(conversacionIndex, 1);
        this.conversaciones.unshift(conversacionActual);

        // Se actualizan datos ultimo mensaje
        this.ultimoMensajeGlobalIndex = mensajeContenido.mensaje_plataforma_id;
        // this.ultimoMensajeGlobalFecha = mensaje.mensajeFecha;

        // Se actualiza cache
        this.actualizarCache();
        console.log("Finaliza update de mensaje");
        this.$conversacionEventBus.$emit(
          "actualizar-conversaciones-mensaje-recibido",
          this.conversaciones
        );
        if (mensajeContenido.tipo == "Recibido") this.reproducirNotificacion();
      }
    },
    actualizarCache(
      tipo = "mensajes",
      reiniciarIntervaloSincronizacion = true
    ) {
      if (tipo == "todo")
        localStorage.setItem("privilegioMensajes", this.privilegioMensajes);

      localStorage.setItem("contactos", JSON.stringify(this.contactos));

      localStorage.setItem(
        "conversaciones",
        JSON.stringify(this.conversaciones)
      );
      localStorage.setItem(
        "ultimoMensajeGlobalIndex",
        this.ultimoMensajeGlobalIndex
      );
      this.ultimaSincronizacionFecha = this.fechaActual();
      localStorage.setItem(
        "ultimaSincronizacionFecha",
        this.ultimaSincronizacionFecha
      );
      if (reiniciarIntervaloSincronizacion)
        this.reiniciarIntervaloFechaUltimaSincronizacion();
    },
    cerrarMQ() {
      if (this.MQClienteStomp) {
        console.log("Desconectando de RabbitMQ");
        this.MQClienteStomp.deactivate(() => {});
      }
    },
    manejarActualizacionConversaciones(conversaciones) {
      this.conversaciones = conversaciones;
    },
    reiniciarIntervaloFechaUltimaSincronizacion() {
      console.log("Reiniciando intervaldo");
      clearInterval(this.intervaloId);
      console.log(this.intervaloId);
      this.intervaloId = setInterval(
        this.verificarFechaUltimaSincronizacion,
        60000
      );
      console.log("intervaloId");
      console.log(this.intervaloId);
    },
    verificarFechaUltimaSincronizacion() {
      let fechaActual = this.dayjs(this.fechaActual());
      let fechaUltimoMensaje = this.dayjs(this.ultimaSincronizacionFecha);
      let diferenciaMinutos = fechaActual.diff(fechaUltimoMensaje, "minutes");

      console.log("Verificando fecha sincronizacion");
      console.log(diferenciaMinutos);

      // Se valida tiempo desde ultimo mensaje en minutos
      if (diferenciaMinutos > 4) this.obtenerUltimosMensajes(false);
    },
    actualizarStatusMensajeEnviado(messagesId, statusNuevo) {
      console.log("actualizarStatusMensajeEnviado");
      this.conversaciones.forEach((conv) => {
        let index = conv.mensajes.findIndex(
          (men) => men.twilio_message_sid == messagesId
        );
        if (index >= 0) {
          conv.mensajes[index].status_whatsapp = statusNuevo;
          console.log(conv.mensajes[index]);
          this.actualizarCache("mensajes", false);
          this.$conversacionEventBus.$emit(
            "actualizar-conversaciones-mensaje-recibido",
            this.conversaciones
          );
        }
      });
    },
    actualizarConversacionUltimoMensajeVisto(conversacionObj) {
      let index = this.conversaciones.findIndex(
        (conv) =>
          conv.lead_id == conversacionObj.lead_id &&
          conv.plataforma == conversacionObj.plataforma
      );
      this.conversaciones[index].ultimo_mensaje_visto_id =
        conversacionObj.ultimo_mensaje_visto_id;

      this.actualizarCache("mensajes", false);
    },
    reproducirNotificacion() {
      this.$refs.audioNotificacion.currentTime = 0;
      this.$refs.audioNotificacion.play();
    },
  },
};
