
import LoginService from "@/Services/LoginService";
const loginService = new LoginService();
export default {
  name: "menu-layout-global-configuraciones",
  methods: {
    async cerrarSesion() {
      this.loader(true);
      await loginService
        .realizarLogout({})
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          this.loader(false);
        });
    },
  },
};
